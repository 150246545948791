<script setup>
const props = defineProps({
  banners: {
    type: Array,
    required: true,
  },
  isStaticImg: {
    type: Boolean,
    required: false,
  },
});

const currentIndex = ref(0);
const resize = "1900x1080";
const bucket = "images";

let intervalId;
onMounted(() => {
  intervalId = setInterval(() => {
    currentIndex.value = (currentIndex.value + 1) % (props.banners?.length || 1);
  }, 4500);
});

onUnmounted(() => clearInterval(intervalId));

function getCloudfrontPath({ src = "https://ddkwzdwevw9u0.cloudfront.net/fallback-image.png", bucket, resize }) {
  if (src.startsWith("blob:")) {
    return { url: src };
  }

  // Separating domain and path parts from image url
  const { CDN_URL } = useRuntimeConfig().public;

  const protocolSeparatorIndex = src.indexOf("://");

  const pathSeparatorIndex = src.indexOf("/", protocolSeparatorIndex + 3); // Skip past "://"

  // Change url domain to CDN domain if provided
  const domain = CDN_URL || src.substring(0, pathSeparatorIndex);

  const path = src.substring(pathSeparatorIndex);

  return {
    url: `${domain}/${bucket}/fit-in/${resize}${path}`,
  };
}

watch(props.banners, (newBanners) => {
  if (!isStaticImg && newBanners.length) {
    const links = newBanners.map((el) => {
      const { url } = getCloudfrontPath({ src: el.image, bucket, resize });

      return {
        rel: "preload",
        as: "image",
        href: url,
      };
    });

    useHead({
      link: links,
    });
  }
});
</script>

<template>
  <div class="relative">
    <div class="relative mx-auto h-[300px] w-full overflow-hidden bg-gray-100 dark:bg-[#00004de7]">
      <TransitionGroup
        enter-active-class="transition-all duration-1000 ease-out"
        enter-from-class="translate-x-full opacity-25 blur-xl"
        enter-to-class="translate-x-0"
        leave-active-class="transition-all duration-1000 ease-out"
        leave-from-class="translate-x-0 opacity-100"
        leave-to-class="-translate-x-full opacity-25 blur-xl"
      >
        <AtomImageNuxtImage
          :key="`image-${banners[currentIndex]?._id}`"
          class="absolute z-10 size-full object-cover"
          :src="banners[currentIndex]?.image"
          :bucket-alias="bucket"
          :resize="resize"
          alt="Cover Image"
        />
        <AtomImageNuxtImage
          :key="`image-second-${banners[currentIndex + 1]?._id || banners[0]?.image}`"
          class="absolute z-0 size-full object-cover"
          :src="banners[currentIndex + 1]?.image || banners[0]?.image"
          :bucket-alias="bucket"
          :resize="resize"
          alt="Cover Image"
        />
        <p
          :key="`description-${banners[currentIndex]?._id}`"
          class="absolute top-[45%] z-[11] line-clamp-5 max-w-[200px] -translate-y-1/2 px-5 py-1 text-lg font-bold tracking-tight text-white drop-shadow-xl sm:top-[40%] sm:line-clamp-3 sm:max-w-lg sm:px-10 sm:text-xl lg:max-w-3xl lg:px-20 lg:text-3xl"
        >
          {{ banners[currentIndex]?.description }}
        </p>
      </TransitionGroup>
      <slot>
        <div
          class="absolute bottom-6 z-[11] flex w-full gap-4 px-5 text-center sm:bottom-[15%] sm:gap-x-4 sm:gap-y-0 sm:px-10 lg:px-20"
        >
          <NuxtLink
            to="/explore"
            class="flex items-center rounded-full bg-gradient-blue px-4 py-1.5 text-sm font-bold text-white transition-all hover:shadow-around hover:shadow-white/50 active:scale-90 sm:w-fit sm:px-8 sm:py-3 lg:text-lg"
            >Explore</NuxtLink
          >
          <NuxtLink
            to="/create"
            class="block rounded-full border-2 border-white bg-transparent px-4 py-1.5 text-sm font-bold text-white transition-all hover:bg-white/25 hover:shadow-around hover:shadow-white/50 active:scale-90 sm:w-fit sm:px-8 sm:py-3 lg:text-lg"
            >Create</NuxtLink
          >
        </div>
      </slot>
    </div>
  </div>
</template>
