<template>
  <div class="">
    <div ref="wrapper" class="wrapper">
      <ul ref="skeleton-collection" class="skeleton-collection pb-4">
        <div v-for="i in 6" :key="i" ref="card" class="group animate-pulse">
          <div class="mx-6 flex size-full max-w-[160px] flex-col lg:mx-3">
            <div
              class="mt-3 block aspect-square h-auto w-full max-w-[127.3px] rounded-full bg-gray-300 shadow-around shadow-black/25 dark:dark:bg-[#2D059E] dark:shadow-[#00bbba]/50 sm:max-w-[136px]"
            ></div>
            <p class="mt-4 h-4 w-[calc(100%-24px)] rounded-3xl bg-gray-300 dark:dark:bg-[#2D059E] sm:h-6 xl:h-7"></p>
          </div>
        </div>
      </ul>
    </div>
  </div>
</template>

<style lang="scss">
.wrapper {
  @apply w-full m-auto relative;
}

.wrapper .skeleton-collection {
  display: grid;
  grid-auto-flow: column;
  overflow-x: auto;
  border-radius: 8px;
  scroll-behavior: smooth;
  scrollbar-width: none;
}

.skeleton-collection::-webkit-scrollbar {
  @apply hidden;
}

.skeleton-collection.no-transition {
  @apply scroll-auto;
}

.skeleton-collection.dragging .card {
  @apply select-none cursor-grabbing;
}

.skeleton-collection :where(.card) {
  @apply flex justify-center items-center;
}

@media screen and (min-width: 300px) {
  .wrapper .skeleton-collection {
    grid-auto-columns: calc(100% / 2.3);
  }
}

@media screen and (min-width: 640px) {
  .wrapper .skeleton-collection {
    grid-auto-columns: calc(100% / 3.3);
  }
}

@media screen and (min-width: 768px) {
  .wrapper .skeleton-collection {
    grid-auto-columns: calc(100% / 4);
  }
}

@media screen and (min-width: 1024px) {
  .wrapper .skeleton-collection {
    grid-auto-columns: calc(100% / 5);
  }
}

@media screen and (min-width: 1280px) {
  .wrapper .skeleton-collection {
    grid-auto-columns: calc(100% / 6);
  }
}

@media screen and (min-width: 1536px) {
  .wrapper .skeleton-collection {
    grid-auto-columns: calc(100% / 6);
  }
}
</style>
