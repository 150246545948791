<script setup>
const { $api } = useNuxtApp();
const userStore = useUserStore();

// ==================== CONSTANTS ====================

const count = 8;
const featuredPage = ref(1);
const freshPage = ref(1);
const popularPage = ref(1);
const freshBuffer = ref([]);
const popularBuffer = ref([]);
const featuredBuffer = ref([]);

// ==================== FETCH ====================

const { data: banners, pending } = $api.get("banner", { key: "banner", default: () => ({ data: [] }) });

const [{ data: popular }, { data: fresh }, { data: featured }] = await Promise.all([
  $api.get("slider/popular", {
    key: `popular|${userStore?.user?._id}`,
    params: {
      count,
      page: popularPage,
    },
    default: () => ({ data: [], total: 0 }),
    server: true,
  }),

  $api.get("slider/new", {
    key: `new|${userStore?.user?._id}`,
    params: {
      count,
      page: freshPage,
    },
    default: () => ({ data: [], total: 0 }),
    server: true,
  }),
  $api.get("slider/featured", {
    key: `featured|${userStore?.user?._id}`,
    params: {
      count,
      page: featuredPage,
    },
    default: () => ({ data: [], total: 0 }),
    server: true,
  }),
]);

const { data: nftCollection, pending: pendingCollection } = $api.get("collection", {
  key: "collection",
  default: () => ({ data: [], total: 0 }),
});

// ==================== WATCHERS ====================

watch(
  fresh,
  () => {
    // this shitty condition I use to avoid duplicates
    if (Array.isArray(fresh.value?.data) && (!freshBuffer.value.length || freshPage.value > 1)) {
      freshBuffer.value.push(...fresh.value.data);
    }
  },
  { immediate: true }
);

watch(
  popular,
  () => {
    // this shitty condition I use to avoid duplicates
    if (Array.isArray(popular.value?.data) && (!popularBuffer.value.length || popularPage.value > 1)) {
      popularBuffer.value.push(...popular.value.data);
    }
  },
  { immediate: true }
);

watch(
  featured,
  () => {
    // this shitty condition I use to avoid duplicates
    if (Array.isArray(featured.value?.data) && (!featuredBuffer.value.length || featuredPage.value > 1)) {
      featuredBuffer.value.push(...featured.value.data);
    }
  },
  { immediate: true }
);

// ==================== FUNCTIONS ====================

function syncSliders(id, isLiked, excludedType) {
  function synchronize(bufferType) {
    const index = bufferType.value.map((el) => el._id).indexOf(id);

    if (index !== -1) {
      bufferType.value[index] = {
        ...bufferType.value[index],
        likedByUser: isLiked,
      };
    }
  }

  if (excludedType !== "featured") {
    synchronize(featuredBuffer);
  }

  if (excludedType !== "fresh") {
    synchronize(freshBuffer);
  }

  if (excludedType !== "popular") {
    synchronize(popularBuffer);
  }
}
</script>

<template>
  <div>
    <Title>Home</Title>
    <MoleculeClientWrapper :loading="pending">
      <template #body>
        <MoleculeHeroBase :banners="banners.data" />
      </template>
      <template #skeleton>
        <div class="h-[300px] w-full animate-pulse bg-gray-300 dark:dark:bg-[#2D059E] lg:h-[300px]"></div>
      </template>
    </MoleculeClientWrapper>

    <div class="bg-gray-100 dark:bg-home-sections">
      <section class="mx-auto w-full max-w-[1920px] pt-6 sm:pt-9 lg:px-10 xl:px-20">
        <h2 class="mb-4 pl-4 text-2xl font-semibold text-black dark:text-white sm:text-4xl">Collections</h2>

        <MoleculePendingWrapper :loading="pendingCollection">
          <template #body>
            <MoleculeNativeCarousel
              v-if="nftCollection?.total"
              :count="nftCollection.data?.length"
              :total="nftCollection.total"
              class="breakpoints_collection"
            >
              <template #content>
                <li v-for="item in nftCollection.data" :key="item._id" ref="card" class="'w-full">
                  <MoleculeCardCollection :disabled="!['BNFT', 'CEEK'].includes(item.type)" :collection="item" />
                </li>
              </template>
            </MoleculeNativeCarousel>
          </template>
          <template #skeleton> <MoleculeCardCollectionSkeleton /></template>
        </MoleculePendingWrapper>
      </section>
      <section class="mx-auto w-full max-w-[1920px] py-6 sm:py-9 lg:px-10 xl:px-20">
        <h2 class="mb-2 pl-4 text-2xl font-semibold text-black dark:text-white sm:text-4xl">Featured</h2>
        <MoleculePendingWrapper :loading="!featuredBuffer.length">
          <template #body>
            <MoleculeNativeCarousel
              :total="featured.total"
              :count="featuredBuffer.length"
              class="breakpoints_default"
              @give-me-more="featuredPage += 1"
            >
              <template #content>
                <li v-for="item in featuredBuffer" :key="item._id" :ref="`card-${item._id}`" class="card">
                  <MoleculeCard
                    :nft="item"
                    :preload-image="true"
                    loading-image="eager"
                    class="shadow-gradient z-[2]"
                    @nft-like-action="(val) => syncSliders(item._id, val, 'featured')"
                  />
                </li>
              </template>
            </MoleculeNativeCarousel>
          </template>
          <template #skeleton> <MoleculeCardCarouselSkeleton /> </template>
        </MoleculePendingWrapper>
      </section>

      <section class="mx-auto w-full max-w-[1920px] pb-6 dark:pt-6 sm:pb-9 dark:sm:pt-9 lg:px-10 xl:px-20">
        <h2 class="mb-2 pl-4 text-2xl font-semibold text-black dark:text-white sm:text-4xl">New</h2>

        <MoleculePendingWrapper :loading="!freshBuffer.length">
          <template #body>
            <MoleculeNativeCarousel
              :total="fresh.total"
              :count="freshBuffer.length"
              class="breakpoints_default"
              @give-me-more="freshPage += 1"
            >
              <template #content>
                <li v-for="item in freshBuffer" :key="item._id" :ref="`card-${item._id}`" class="card">
                  <MoleculeCard
                    :nft="item"
                    class="shadow-gradient z-[2]"
                    @nft-like-action="(val) => syncSliders(item._id, val, 'fresh')"
                  />
                </li>
              </template>
            </MoleculeNativeCarousel>
          </template>
          <template #skeleton> <MoleculeCardCarouselSkeleton /> </template>
        </MoleculePendingWrapper>
      </section>

      <section class="mx-auto w-full max-w-[1920px] pb-6 dark:pt-6 sm:pb-9 dark:sm:pt-9 lg:px-10 xl:px-20">
        <h2 class="mb-2 pl-4 text-2xl font-semibold text-black dark:text-white sm:text-4xl">Popular</h2>
        <MoleculePendingWrapper :loading="!popularBuffer.length">
          <template #body>
            <MoleculeNativeCarousel
              :total="popular.total"
              :count="popularBuffer.length"
              class="breakpoints_default"
              @give-me-more="popularPage += 1"
            >
              <template #content>
                <li v-for="item in popularBuffer" :key="item._id" :ref="`card-${item._id}`" class="card">
                  <MoleculeCard
                    :nft="item"
                    class="shadow-gradient z-[2]"
                    @nft-like-action="(val) => syncSliders(item._id, val, 'popular')"
                  />
                </li>
              </template>
            </MoleculeNativeCarousel>
          </template>
          <template #skeleton> <MoleculeCardCarouselSkeleton /> </template>
        </MoleculePendingWrapper>
      </section>

      <div class="bg-gray-100 pb-7 dark:bg-[#132769] dark:pt-7">
        <NuxtLink to="/explore">
          <MoleculeButtonGradient
            class="px-12 py-3 text-lg font-semibold md:px-24 md:py-6 md:text-xl"
            aria-label="Discover More"
            >Discover More</MoleculeButtonGradient
          >
        </NuxtLink>
      </div>
    </div>
  </div>
</template>
